import React from "react";
import "./style.scss";
import { connect } from "react-redux";

const AccountManager = ({ onSamplePopupClose, state }) => {
  return (
    <div className="lead-popup mob-l am">
      <img
        className="waitlist-close"
        onClick={onSamplePopupClose}
        src="/images/common/custom-color-cross.svg"
      />
      <p className="lead-text">
        {/* Leadtime  */}
        <p className="lead-sub-text">
          {/* Everything is made to order. */}
          Our team of expert account managers is here to help you navigate the
          complex landscape of procurement decisions, branding strategies, and
          marketing tactics. With years of industry experience, we offer
          personalized support that goes beyond basic assistance. We work with
          you to mold your ideas and develop a comprehensive plan that fits your
          unique needs, handling everything from pricing exercises to
          fulfillment. This allows you to focus on what you do best - running
          your business. Partner with us and don't settle for mediocre results.
          Let us take your business to the next level and join us on an exciting
          journey of success!
        </p>
      </p>
    </div>
  );
};

const mapStateToProps = (state) => ({
  state: state.products,
});

// export default AccountManager;
export default connect(mapStateToProps, {})(AccountManager);
