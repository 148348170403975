import React, { Fragment, useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  sendPreOrderEmailHandler,
  sendContactUsEmailHandler,
} from "../../../../../../actions/product/action";
import ModalTemplate from "../../../../../common/modal";
import "./style.scss";
import { useForm } from "react-hook-form";
import Toaster from "../../../../../common/Toaster";
import { navigate } from "gatsby";
import AccountManager from "../account-manager";

const MemberBenefits = ({
  product,
  showModal,
  changeModalState,
  sendPreOrderEmailHandler,
  state,
  background="rgba(0,0,0,.278)",
  hideCross=false
}) => {
  const [refresh, doRefresh] = useState(0);
  const [toastrMsg, setToastrMsg] = useState("");
  const [toastrType, setToastrType] = useState("success");
  const [isMobile, setMobile] = useState(false);
  const [aMPopup, setAMPopup] = useState(false);

  const [modalStyle, setmodalStyle] = useState({
    content: {
      background: "#FFF",

      border: "0.5px solid #ddbdbd",
      inset: "15px 20vw",
      borderRadius: "15px",
      zIndex: 10,
    },
    overlay: {
      zIndex: 10,
      background: background,
    },
  });

  useEffect(() => {
    if (window.innerWidth < 768) setMobile(true);
    const handleWindowSizeChange = () => {
      window.innerWidth < 768 ? setMobile(true) : setMobile(false);
    };
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    // console.log(showSpecialRequestType)
    if (isMobile) {
      setmodalStyle({
        ...modalStyle,
        content: {
          inset: "30px 5vw",
          borderRadius: "10px",
          zIndex: 10,
        },
      });
    } else {
      setmodalStyle({
        ...modalStyle,
        content: {
          inset: "15px 20vw",
          borderRadius: "15px",
          zIndex: 10,
        },
      });
    }
  }, [isMobile]);

  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  //   reset,
  // } = useForm();

  // const onSubmit = async (data, e) => {

  //   if(data.email){
  //     var html = `You have a Bulk Order Request <br /> <br />${data.email}`;
  //     var subject = `V2 - Bulk Order Request`;
  //     var sendContactUsReq = await sendContactUsEmailHandler({
  //     html,
  //     subject
  //     });

  //     if (!sendContactUsReq || sendContactUsReq.error) {
  //       setToastrMsg(sendContactUsReq.errorData);
  //       setToastrType("error");
  //       doRefresh((prev) => prev + 1);
  //     } else {
  //       setToastrMsg("Request has been sent!!");
  //       setToastrType("success");
  //       doRefresh((prev) => prev + 1);
  //       onClosePopup();
  //     }
  //   }

  //   // sendPreOrderEmailHandler({
  //   //   email: data.email,
  //   //   productUniqueId: product.uniqueId,
  //   //   productStyleNumber: product.productStyle,
  //   // });

  // };

  const onClosePopup = useCallback(async (data, e) => {
    // doRefresh((prev) => prev + 1);
    // reset();
    changeModalState();
    // setTimeout(() => {
    //   navigate("/products/thank-you");
    // }, 1000);
    // changeModalState();
    // navigate("/products/thank-you");
  }, []);

  return (
    <Fragment>
      <Toaster refresh={refresh} type={toastrType} message={toastrMsg} />
      {/* <div
      className='modal-backdrop-close'
      // onClick={changeModalState}
      > */}

      <ModalTemplate
        showModal={showModal}
        modalStyle={modalStyle}
        onRequestClose={changeModalState}
      >
        <div className="waitlist">
         {!hideCross && <img
            className="waitlist-close small"
            onClick={changeModalState}
            src="/images/common/custom-color-cross.svg"
          />}
          <div className="members-perks roboto-text">
            <div className="mem-title">
              AV <b>BOSS</b> MEMBERS
            </div>
            <div className="built-brands">Built for brands</div>
            <div className="mem-subtitle">
              <b>FREE</b> membership. <br />
              Brands we accept will enjoy AV’s <br />
              perks and save thousands.
              {/* Members enjoy AVendor’s 
              perks & save thousands. */}
            </div>
            <div className="perks-box">
              <div className="perks-title">Perks</div>
              <div className="center-box">
              <div className="perks-line em pos-rel">
                <img className="perk4" src="/images/common/sample-perks4.svg" />
                Dedicated <span className="blue-underline" onClick={() => setAMPopup(true)}>Account Manager</span>
                {aMPopup && (
                    <AccountManager onSamplePopupClose={() => setAMPopup(false)} />
                  )}
                  {aMPopup && (
                    <div
                      className="black-overlay"
                      onClick={() => setAMPopup(false)}
                    ></div>
                  )}
              </div>
              <div className="perks-line">
                <img src="/images/common/sample-perks1.svg" />
                <span className="mr-cc">
                  <b>75%</b>
                </span>{" "}
                Less Sampling Fee
              </div>
              <div className="perks-line">
                <img src="/images/common/sample-perks1.svg" />
                <span className="mr-cc">
               <b>-10%</b>
                </span>{" "}
                Production Orders
              </div>
              <div className="perks-line">
                <img src="/images/common/sample-perks2.svg" />
                <b><span className="mr-cc">FREE</span></b> Customization
              </div>
              <div className="perks-line li">
                <li></li>
                Custom Color
              </div>
              {/* <div className="perks-line li">
                <li></li>
                Custom drawstring
              </div> */}
              <div className="perks-line li">
                <li></li>
                Silicone Wash
              </div>
              <div className="perks-line li">
                <li></li>
                Antimicrobial
              </div>
              <div className="perks-line li">
                <li></li>
                Woven Label & Labor
              </div>
              <div className="perks-line li">
                <li></li>
                Print Label & Labor
              </div>
              <div className="perks-line li">
                <li></li>
                Hangtag & Labor
              </div>
              <div className="perks-line li">
                <li></li>
                Care-label
              </div>
              {/* <div className="perks-line li">
                <li></li>
                Graphic Print Setup
              </div> */}

              <button className="atj-mb" type="submit" onClick={() => navigate(`/onboarding/step-one`)}>Apply to Join</button>
              </div>
            </div>
          </div>
          

          {/* <div className="waitlist-title">Volume Order!</div>
          <div className="waitlist-desc">
           Contact us for discount for orders over 750 units per color.
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="waitlist-email">
            <textarea
              {...register("email", {
                required: true
              })}
              placeholder="Query"
              rows={5}
            />
            {errors.email && (
              <p className="error-class">Please enter a valid Email</p>
            )}
            <button type="submit">Contact us!</button>
          </form> */}
          {/* <div className="waitlist-subtext">Factory reached max capacity.</div> */}
        </div>
      </ModalTemplate>
      {/* </div> */}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  state: state.products,
});

export default connect(mapStateToProps, {
  sendPreOrderEmailHandler,
})(MemberBenefits);
