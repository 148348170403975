import React from "react";
import MemberBenefits from "../../components/cards/CollectionsCard/product/modals/member-benefits";

const memberDetails = () => {
  const changeModalState2 = () => {};

  return (
    <>
      <MemberBenefits showModal={true} changeModalState={changeModalState2} background="#004e93" hideCross={true}/>
    </>
  );
};

export default memberDetails;
